<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">增值服务名称:</label>
				<el-input v-model="appreciatName" style="width: 200px;" clearable placeholder="请输入增值服务名称"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addIncrement'">
					<el-button type="primary" style="margin-left:30px;width:125px;" @click="AddEmployee">创建增值服务</el-button>
				</button-permissions>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border style="width: 100%">
				<el-table-column prop="appreciatName" label="增值服务名称" width="300"></el-table-column>
				<el-table-column prop="appreciatText" label="概述" ></el-table-column>
                <el-table-column prop="appreciatPrice" label="价格" width="100"></el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="200"></el-table-column>
                <el-table-column prop="appreciatCount" label="商品数量" width="100"></el-table-column>
				<el-table-column label="操作" width="300" align="center">
					<template slot-scope="scope">
						<button-permissions :datas="'editIncrement'" style="margin-right: 10px;">
							<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
						</button-permissions>
						<button-permissions :datas="'delIncrement'">
						<el-button type="text" @click="handleLeave(scope.row)" style="color: #F56C6C !important;">删除</el-button>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: right;">
				<el-pagination
					v-if="total"
					style="margin-top:20px;"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page=" pageNo"
					:page-sizes="[10, 20, 30, 40, 50]"
					:page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
				></el-pagination>
			</div>
		</div>

		<!-- 添加员工弹窗 -->
		<el-dialog
			:title="`${editDiaTitle}增值服务`"
			:visible.sync="dialogFormVisible"
			v-if="dialogFormVisible"
			width="550px"
		>
			<el-form :model="ruleForm" ref="ruleForm">
				<el-form-item label="增值服务名称 :" label-width="150px" prop="appreciatName" :rules="[{required:true,message:'增值服务名称不能为空',trigger: 'blur'}]">
					<el-input placeholder="请输入增值服务名称" maxlength="30" v-model="ruleForm.appreciatName" style="width: 300px;"></el-input>
				</el-form-item>
                <el-form-item 
                    label="增值服务价格 :" 
                    label-width="150px" 
                    prop="appreciatPrice" 
                    :rules="[{validator: checkAppreciatPrice, trigger: ['change','blur']}]">
					<el-input type='number' placeholder="请输入增值服务价格" v-model="ruleForm.appreciatPrice" style="width: 300px;" ></el-input>
				</el-form-item>
				<el-form-item label="增值服务概述 :" label-width="150px" prop="appreciatText" :rules="[{required:true,message:'备注不能为空',trigger: 'blur'}]">
					<el-input
						placeholder="请输入增值服务概述"
						maxlength="150"
						v-model="ruleForm.appreciatText"
						style="width: 300px;"
						type="textarea"
					></el-input>
				</el-form-item>
			</el-form>
			<div style="padding-top: 20px;">
				<el-button @click="cancelSave" style="width: 100px;margin-left: 100px;">关 闭</el-button>
				<el-button
					type="primary"
					@click="submitSave"
					style="width: 100px;margin-left: 30px;"
					:loading="loading"
				>保 存</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import {
	getAppreciatList,
	addAppreciat,
	updateAppreciat,
	delAppreciat
} from '@/api/goods'
import buttonPermissions from '@/components/buttonPermissions';

export default {
	components: {
		buttonPermissions
	},
	name: 'increment',
	data () {
		return {
			editDiaTitle: '添加',
			loading: false,
			appreciatName: null,    //服务名称

			tableData: [],
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dialogFormVisible: false,
			ruleForm: {
				id: '',
				appreciatName: '',  //服务名称
                appreciatText:'',   //服务概述
                appreciatPrice:'',   //服务价格
			},
		}
	},
	mounted () {

	},
	beforeMount () {
		this.getList();
	},
	computed: {

	},
	methods: {
		async getList () {
			this.loading = true;
			const res = await getAppreciatList({
				appreciatName: this.appreciatName,
				pageSize: this.pageSize,
				pageNo: this.pageNo,
			})
			this.loading = false;
			if (res.success) {
				this.tableData = res.data.records;
				console.log(this.tableData);
				this.total = res.data.total;
			}
		},
		// 查询
		handleFilter () {
			this.pageNo = 1;
			this.getList();
		},

		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getList();
		},
		// 添加员工
		AddEmployee () {
			this.editDiaTitle = '添加'
			this.ruleForm = {
				id: '',
				appreciatName: '',   //服务名称
                appreciatText:'',   //服务概述
                appreciatPrice: '',  //服务价格
			}
			this.dialogFormVisible = true;
		},
		// 编辑员工信息
		handleEdit (row) {
			this.editDiaTitle = '编辑'
			this.ruleForm = {
				id: row.id,
				appreciatName: row.appreciatName,   //服务名称
                appreciatText: row.appreciatText,   //服务概述
                appreciatPrice: row.appreciatPrice,  //服务价格
			}
			this.dialogFormVisible = true;
		},

		// 删除
		async handleLeave (row) {
			this.$confirm('删除后将无法恢复, 是否继续?', '删除', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {
				let result = await delAppreciat({
					id: row.id
				});
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.handleFilter()
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
				this.getMenlist();
			}).catch(() => {
				console.log('取消删除');
			});
		},

		// 保存员工信息
		submitSave () {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					const loading = this.$loading({
						lock: true,
					});
					try {
						this.loading = true
						let data = {
							id: this.ruleForm.id,
							appreciatName: this.ruleForm.appreciatName,   //服务名称
                            appreciatText: this.ruleForm.appreciatText,   //服务概述
                            appreciatPrice: this.ruleForm.appreciatPrice,  //服务价格
						}
						let result;
						if (this.ruleForm.id) {
							result = await updateAppreciat(data);
						} else {
							result = await addAppreciat(data);
						}

						if (result.success) {
							if (this.editDiaTitle == '添加') {
								this.$message({
									showClose: true,
									type: 'success',
									message: '添加成功！'
								});
							} else {
								this.$message({
									showClose: true,
									type: 'success',
									message: '保存成功！'
								});
							}
							this.dialogFormVisible = false;
                            this.$refs['ruleForm'].resetFields();
                            this.handleFilter();
						} else {
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
					} catch (err) {
						console.log(err);
					} finally {
						this.loading = false
						loading.close();
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
        //关闭弹窗
		cancelSave () {
			this.dialogFormVisible = false;
			this.$refs['ruleForm'].resetFields();
        },
        //校验增值服务价格
        checkAppreciatPrice (rule, value, callback){
            if ((0 < value && value <= 1000000) || value === 0 || value === '0') {
                return callback();
            } else if (value < 0) {
                return callback(new Error('增值服务价格不能为负数'));
            } else {
                return callback(new Error('增值服务价格不能为空'));
            }
        }
	},
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
</style>
<style>
.valitedata-dialog-alert {
	width: 550px !important;
}
</style>
